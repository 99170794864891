import React from 'react'
import Layout from '../components/layout/layout.js'
import Content from '../components/layout/content.js'
import SEO from '../components/parts/seo.js'
import '../scss/_contact.scss'

const Contact = () => {
  const pageTitle = {
    mainTitle: {
      text: 'お問い合わせ'
    },
    subTitle: {
      text: '<Contact />',
      lang: 'en'
    }
  }

  return (
    <Layout pageTitle={pageTitle}>
      <SEO
        title='お問い合わせ'
        description='UWEBへのお問い合わせはこのページより行うことができます。'
        pathname='/contact/'
        article
      />
      <Content.Lead>
        いただきましたお問い合わせメールは内容を確認したのち、必要に応じて対応しております。<br />
        返信にはお時間を頂戴する場合がございますので、あらかじめご了承ください。<br />
        また、入力内容の確認画面、入力内容の確認メールはございませんので、ご注意ください。
      </Content.Lead>
      <Content.Item>
        <form name="contact" method="POST" action="/contact/thanks/" data-netlify="true" netlify-honeypot="bot-field" className="form">
          <input type="hidden" name="form-name" value="contact" />
          <input type="hidden" name="bot-field" />
          <dl className="form__list">
            <div className="form__item">
              <dt className="form__title">
                <label htmlFor="name">お名前</label>
                <span className="form__required">必須</span>
              </dt>
              <dd className="form__data">
                <input type="text" name="name" id="name" placeholder="例）山田 太郎" required aria-required="true" className="form__input" />
              </dd>
            </div>
            <div className="form__item">
              <dt className="form__title">
                <label htmlFor="kana">ふりがな</label>
                <span className="form__required">必須</span>
              </dt>
              <dd className="form__data">
                <input type="text" name="kana" id="kana" placeholder="例）やまだ たろう" required aria-required="true" className="form__input" />
              </dd>
            </div>
            <div className="form__item">
              <dt className="form__title">
                <label htmlFor="email">メールアドレス</label>
                <span className="form__required">必須</span>
              </dt>
              <dd className="form__data">
                <input type="email" name="email" id="email" placeholder="例）example@example.com" required aria-required="true" className="form__input" />
              </dd>
            </div>
            <div className="form__item">
              <dt className="form__title">
                <label htmlFor="url">URL</label>
              </dt>
              <dd className="form__data">
                <input type="text" name="url" id="url" placeholder="例）https://example.com/" className="form__input" />
              </dd>
            </div>
            <div className="form__item">
              <dt className="form__title">
                <label htmlFor="contact_content">お問い合わせ内容</label>
                <span className="form__required">必須</span>
              </dt>
              <dd className="form__data">
                <textarea name="contact_content" id="contact_content" cols="30" rows="8" aria-required="true" required className="form__input"></textarea>
              </dd>
            </div>
          </dl>
          <div className="button button_center">
            <button type="submit" className="button__inner button__inner_main">送信</button>
          </div>
        </form>
      </Content.Item>
    </Layout>
  )
}
export default Contact;
